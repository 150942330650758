$header-background: white;
$main-background: white;
$nav-active: cornflowerblue;
$nav-hover: gray;
$heading: black;
$text: lighten(black,10%);
$link: darken(cornflowerblue,30%);
$link-hover: lighten(cornflowerblue,30%);

body {    
    margin: 0;
    color: $text;
    background: $main-background;
}

a {
    text-decoration: none;
    color: $link;
}

header {
    background: $header-background;
    
    & > a {	
	display: block;
	color: black;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
	padding: .5rem 0;
    }

    & > nav {
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;

	& > ul {
	    list-style: none;
	    margin: 0;
	    padding: 0;

	    & > li {
		display: inline-block;
		margin: 0;
		padding: 0.5rem;
		border-bottom: 2px solid transparent;
		
		
		& > a {
		    color: black;
		    font-weight: bold;
		    font-size: 1.5rem;

		    &:hover {
			transition: width, .2s;
			border-bottom: 2px solid $nav-hover;
		    }

		    &.active {
			border-bottom: 2px solid $nav-active;
		    }
		}
	    }
	}
    }
    
}

main {
    max-width: 60rem;
    margin: auto;    
    padding: .5rem;

    font-size: 1.1rem;

    & h1, h2, h3 {
	color: $heading;
    }

    & h1 {
	font-size: 1.5rem;
    }

    & h2 {
	font-size: 1.4rem;
    }

    & h3 {
	font-size: 1.3rem;
    }

    & a {
	color: $link;
	
	&:hover {
	    transition: width, .2s;
	    background: $link-hover;
	}
    }
}

.bibliography {
}

.bibliography li {
    padding: .2rem;
}

.title {
}

.coauthors, .reference {
}
.journal {
font-style: italic; }
.volume {
font-weight: bold; }

.schedule > .event {
    padding: .5em;
    border-radius: .5em;
    background: darken(white,3%);
    margin: .5em;
}
.schedule > .event > .date {
    font-weight: bold;
}

.schedule > .event > .speaker {
    font-variant: small-caps;
}

.schedule > .event > .title {
    font-weight: bold;
}

.schedule > .event > .abstract::before {
}
